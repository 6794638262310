import { Dispatch, useEffect, useState } from 'react';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import './CustomSwitch.scss';

interface CustomSwitchProps {
  user: any;
  onChangeAction: Dispatch<any>;
  labels: {
    on: string;
    off: string;
  };
}

const CustomSwitch = ({ labels, onChangeAction, user }: CustomSwitchProps) => {
  const [switchChecked, setSwitchChecked] = useState<boolean>(false);

  const handleChange = (checked: boolean): void => {
    setSwitchChecked((previousVal) => !previousVal);
    onChangeAction(switchChecked);
  };

  useEffect(() => {}, [switchChecked]);

  useEffect(() => {
    if (user) {
      if (user.blockedUntil) {
        setSwitchChecked((previousVal) => true);
      } else {
        setSwitchChecked((previousVal) => false);
      }
    }
  }, [user]);

  return (
    <>
      <FormControlLabel
        className="custom-switch-label"
        control={
          <Switch
            className="custom-switch"
            sx={{ m: 0.75, mr: 1.5 }}
            checked={switchChecked}
            onChange={(event) => {
              handleChange(event.target.checked);
            }}
            name="isActiveUser"
          />
        }
        label={switchChecked ? labels.off : labels.on}
        labelPlacement="start"
      />
    </>
  );
};

export default CustomSwitch;
