import { Interweave } from 'interweave';
import { FunctionComponent, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import useWindowDimensions from '../../utils/windowDimensions/windowDimensions';
import Modal from '../UI/Modal/Modal';
import './CustomModal.scss';
import { CustomModalType } from './helpers/CustomModalTypes';

interface CustomModalProps {
  isVisible: boolean;
  closeModalHandler: () => void;
  modalConfig: { type: CustomModalType; source: { uri?: string; htmlContent?: string } };
}

const CustomModal: FunctionComponent<CustomModalProps> = memo(
  ({ isVisible, closeModalHandler, modalConfig }) => {
    const { width } = useWindowDimensions();
    const { t } = useTranslation();
    const [numPages, setNumPages] = useState(0);
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    function onDocumentLoadSuccess(numOfPages: any) {
      setNumPages(numOfPages._pdfInfo.numPages);
    }

    return (
      <>
        <Modal isVisible={isVisible} closeModalHandler={closeModalHandler}>
          {modalConfig.type === CustomModalType.PDF && (
            <Document
              className="CustomModal__PDFWrapper"
              file={modalConfig.source.uri}
              error={t('fileDisplay.fileNotFound')}
              onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  className="CustomModal__PDF"
                  scale={width > 600 ? 1 : width / 600}
                />
              ))}
            </Document>
          )}

          {modalConfig.type === CustomModalType.HTML && (
            <div className="CustomModal__HTMLWrapper">
              <Interweave content={modalConfig.source.htmlContent} />
            </div>
          )}
        </Modal>
      </>
    );
  }
);

export default memo(CustomModal);
