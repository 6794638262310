import { useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResultsInfo from '../ResultsInfo/ResultsInfo';
import './ResultsPanel.scss';

interface ResultsPanelProps {
  resultsNumber: number;
  currentPage: number;
  setResultsPerPage: (resultsPerPage: number | null) => void;
  resultsPerPageOptions: (null | number)[];
}

const ResultsPanel = ({
  resultsNumber,
  currentPage,
  setResultsPerPage,
  resultsPerPageOptions
}: ResultsPanelProps) => {
  const [resultPerPageDropdownOpen, setResultPerPageDropdownOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <div className="results-container">
      <Grid
        className="main-grid"
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <ResultsInfo resultsNumber={resultsNumber} currentPage={currentPage} />
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            onClick={() => setResultPerPageDropdownOpen(!resultPerPageDropdownOpen)}
            className="results-per-page-button">
            <Grid item>
              <div className="font-audi-bold">{t('home.results.result')}</div>
            </Grid>
            <Grid item className="arrow-container">
              {'<'}
            </Grid>
            <div className={`dropdown-container ${resultPerPageDropdownOpen ? 'open' : ''}`}>
              {resultsPerPageOptions.map((option, index) => {
                if (option) {
                  return (
                    <div
                      className="dropdown-item"
                      onClick={() => setResultsPerPage(option)}
                      key={`dropdown-item-${index}`}>
                      {option} {t('home.results.contentPerPage')}
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="dropdown-item"
                      onClick={() => setResultsPerPage(option)}
                      key={`dropdown-item-${index}`}>
                      {t('home.results.all')}
                    </div>
                  );
                }
              })}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResultsPanel;
