import { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import SnackbarAlert, {
  ISnackbarProps,
  SnackbarContext
} from './components/SnackbarAlert/SnackbarAlert';
import Router from './routes/Router';
import { isUserLoggedIn } from './services/auth.service';

export const LoginContext = createContext({
  isLoggedIn: false,
  setIsLoggedIn: (isLoggedIn: boolean) => {}
});

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [snack, setSnack] = useState<ISnackbarProps>({
    open: false,
    message: '',
    isError: true
  });
  useEffect(() => {
    isUserLoggedIn().then((value: boolean) => {
      return value ? setIsLoggedIn(true) : setIsLoggedIn(false);
    });
  });

  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <SnackbarContext.Provider value={{ snack, setSnack }}>
        <div className="font-audi-normal">
          <BrowserRouter>
            <Router isLoggedIn={isLoggedIn} />
          </BrowserRouter>
        </div>
        <SnackbarAlert></SnackbarAlert>
      </SnackbarContext.Provider>
    </LoginContext.Provider>
  );
}

export default App;
