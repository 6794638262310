import { Grid } from '@mui/material';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LogsByPersonCodeTable from '../../components/LogsByPersonCodeTable/LogsByPersonCodeTable';
import ResultsPanel from '../../components/ResultsPanel/ResultsPanel';
import SearchWithDatePicker from '../../components/SearchWithDatePicker/SearchWithDatePicker';
import { SnackbarContext } from '../../components/SnackbarAlert/SnackbarAlert';
import TableFooter from '../../components/TableFooter/TableFooter';
import CustomSwitch from '../../components/UI/CustomSwitch/CustomSwitch';
import EventType from '../../enums/event-type.enum';
import { IJWTPayload } from '../../interfaces/models/jwt.payload.model';
import {
  IExportLogsRequest,
  IGetLogsByPersonCodeRequest
} from '../../interfaces/requests/log/log.request';
import { IGetUserByPersonCodeRequest } from '../../interfaces/requests/users/users.request';
import {
  IGetLogItemsByPersonCodeResponse,
  IGetMetaResponse
} from '../../interfaces/responses/log/log.response';
import { IUsersResponse } from '../../interfaces/responses/users/users.response';
import { exportLogs, getLogsByPersonCode } from '../../services/log.service';
import { getUserByPersonCode, lockUser, unlockUser } from '../../services/users.service';
import './LogsByPersonCodePage.scss';

const LogsByPersonCodePage = () => {
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(14, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [resultsPerPage, setResultsPerPage] = useState<null | number>(25);
  const resultsPerPageOtions = [25, 50, 100, null];
  const [filterBy, setFilterBy] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [items, setItems] = useState<IGetLogItemsByPersonCodeResponse[]>();
  const [meta, setMeta] = useState<IGetMetaResponse>();
  const [user, setUser] = useState<IUsersResponse>();

  let { t } = useTranslation();

  let { personCode } = useParams();

  const { setSnack } = useContext(SnackbarContext);

  const token: string = useMemo(() => localStorage.getItem('accessToken')!, []);
  const payload: IJWTPayload = jwt_decode(token);
  const adminPersonCode = payload.personCode;

  const getLogData = async (
    dateFrom: string | null,
    dateTo: string | null,
    limit: number | null,
    page: number
  ) => {
    let resultLimit = 1000;
    if (limit !== null) {
      resultLimit = limit;
    } else if (meta?.totalItems) {
      resultLimit = meta.totalItems;
    }

    const getLogsByPersonCodeRequest: IGetLogsByPersonCodeRequest = {
      dateFrom,
      dateTo,
      limit: resultLimit,
      page,
      search: searchText,
      eventType: filterBy as EventType
    };

    const data = await getLogsByPersonCode(personCode, getLogsByPersonCodeRequest);

    setItems(data.items);
    setMeta(data.meta);
    if (currentPage > 1) setCurrentPage(1); // All filtering must result in a new table
  };

  const getUserData = async () => {
    if (personCode) {
      const getUserByPersonCodeRequest: IGetUserByPersonCodeRequest = {
        personCode
      };

      const data = await getUserByPersonCode(getUserByPersonCodeRequest);
      setUser(data);
    }
  };

  const handleLockOrUnlock = async (userShouldbeUnlocked: boolean) => {
    if (user) {
      if (userShouldbeUnlocked) {
        if (await unlockUser(user.personCode, adminPersonCode)) {
          setSnack({
            isError: false,
            open: true,
            message: t('administratorsPage.modal.successfulUnlocking')
          });
        } else {
          setSnack({
            isError: false,
            open: true,
            message: t('general.error')
          });
        }
      } else {
        if (await lockUser(user.personCode, adminPersonCode)) {
          setSnack({
            isError: false,
            open: true,
            message: t('administratorsPage.modal.successfulLocking')
          });
        } else {
          setSnack({
            isError: false,
            open: true,
            message: t('general.error')
          });
        }
      }
    }
  };

  useEffect(() => {
    getLogData(startDate, endDate, resultsPerPage, currentPage);
    getUserData();
  }, [currentPage, resultsPerPage, filterBy, startDate, endDate, searchText]);

  const exportData = async () => {
    let exportLogsRequest: IExportLogsRequest = {
      personCode,
      search: searchText,
      dateFrom: startDate,
      dateTo: endDate,
      eventType: filterBy as EventType
    };

    if (searchText === null) {
      exportLogsRequest = { ...exportLogsRequest, search: '' };
    }
    if (filterBy === null) {
      exportLogsRequest = { ...exportLogsRequest, eventType: '' as EventType };
    }

    await exportLogs(exportLogsRequest, personCode);
  };

  return (
    <div className="details-by-person-number-container">
      <Grid container direction="column" sx={{ pt: 1 }}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
            <Grid item>
              <Grid container spacing={3} className="font-audi-bold">
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <div className="person-number-info-header">
                        {t('logsByPersonCodePage.personCode')}
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="person-number-info-number">{personCode}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <div className="person-number-info-header">
                        {t('logsByPersonCodePage.cardCode')}
                      </div>
                    </Grid>
                    <Grid item>
                      <div className="person-number-info-number">{user?.cardCode}</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CustomSwitch
                onChangeAction={handleLockOrUnlock}
                user={user}
                labels={{
                  on: t('logsByPersonCodePage.switchOn'),
                  off: t('logsByPersonCodePage.switchOff')
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ pt: 3 }}>
          <SearchWithDatePicker
            searchText={searchText}
            setSearchText={setSearchText}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Grid>
        <Grid item sx={{ mt: -1 }}>
          {meta && (
            <ResultsPanel
              resultsNumber={meta.totalItems}
              currentPage={meta.currentPage}
              setResultsPerPage={setResultsPerPage}
              resultsPerPageOptions={resultsPerPageOtions}
            />
          )}
        </Grid>
        <Grid item>
          {items && <LogsByPersonCodeTable user={user} items={items} setFilterBy={setFilterBy} />}
        </Grid>
        <Grid item>
          {meta && (
            <TableFooter
              resultsNumber={meta.totalItems}
              currentPage={meta.currentPage}
              pageCount={meta.totalPages}
              setCurrentPage={setCurrentPage}
              exportData={exportData}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LogsByPersonCodePage;
