import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { eventList } from '../../utils/eventList';
import { useTranslation } from 'react-i18next';
import '../HomeTable/HomeTable.scss';
import { IGetLogItemsByPersonCodeResponse } from '../../interfaces/responses/log/log.response';
import moment from 'moment';
import { IUsersResponse } from '../../interfaces/responses/users/users.response';
import CauseType from '../../enums/cause-type.enum';

interface DataTableProps {
  setFilterBy: (filterBy: string) => void;
  items: IGetLogItemsByPersonCodeResponse[];
  user: IUsersResponse | undefined;
}

export default function LogsByPersonCodeTable({ setFilterBy, items, user }: DataTableProps) {
  const [resultPerPageDropdownOpen, setResultPerPageDropdownOpen] = useState(false);

  const { t } = useTranslation();

  const rows = items;

  return (
    <TableContainer className="custom-table">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="header-text">
            <TableCell
              align="left"
              className="cursor-pointer"
              onClick={() => setResultPerPageDropdownOpen(!resultPerPageDropdownOpen)}>
              {t('homeTable.event')}
              <div className="arrow-container">{'<'}</div>
              <div className={`dropdown-container ${resultPerPageDropdownOpen ? 'open' : ''}`}>
                <div
                  className="dropdown-item"
                  onClick={() => setFilterBy('')}
                  key={`dropdown-item-all`}>
                  {t(`enum.eventType.All`)}
                </div>
                {eventList.map((option, index) => {
                  return (
                    <div
                      className="dropdown-item"
                      onClick={() => setFilterBy(option.value)}
                      key={`dropdown-item-${index}`}>
                      {t(`enum.eventType.${option.value}`)}
                    </div>
                  );
                })}
              </div>
            </TableCell>
            <TableCell align="left">{t('homeTable.reason')}</TableCell>
            <TableCell align="left">{t('homeTable.message')}</TableCell>
            <TableCell align="right">{t('homeTable.date')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className={`table-body ${row.isCritical && 'danger'}`}>
              <TableCell align="left">{t('enum.eventType.' + row.eventType)}</TableCell>
              <TableCell align="left">
                {row.causeType === CauseType.ADMINISTRATOR
                  ? `${t('enum.causeType.' + row.causeType)}: ${row.adminPersonCode}`
                  : t('enum.causeType.' + row.causeType)}
              </TableCell>
              <TableCell align="left">{row.message}</TableCell>
              <TableCell align="right">
                {moment(row.createdAt).format('YYYY.MM.DD. HH:mm:ss')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
