import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PdfIcon } from '../../assets/icons/document-pdf-large.svg';
import FileUploadWithTable from '../../components/FileUploadWithTable/FileUploadWithTable';
import { SnackbarContext } from '../../components/SnackbarAlert/SnackbarAlert';
import CustomCard from '../../components/UI/CustomCard/CustomCard';
import FileType from '../../enums/file-type.enum';
import { IGetFileAttributesResponse } from '../../interfaces/responses/file/file.response';
import { getFileAttributes, setFile } from '../../services/files.service';
import './PrivacyNoticePage.scss';

const PrivacyNoticePage = () => {
  const [item, setItem] = useState<IGetFileAttributesResponse | null>(null);

  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const uploadFile = async (file: File) => {
    try {
      await setFile(file, FileType.DATA_POLICY);
      setSnack({
        isError: false,
        open: true,
        message: t('fileUpload.success')
      });
    } catch (error) {
      setSnack({
        isError: true,
        open: true,
        message: t('fileUpload.error')
      });
    }
    getData();
  };

  const getData = async () => {
    setItem(await getFileAttributes(FileType.DATA_POLICY));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="privacy-notice-page">
      <h2 className="font-audi-extended-bold header-text">
        {t('privacyNoticePage.privacyNotice')}
      </h2>
      <div className="card-container">
        <CustomCard
          icon={<PdfIcon />}
          text={t('privacyNoticePage.uploadPrivacyNotice')}
          uploadFile={uploadFile}
        />
      </div>
      <FileUploadWithTable file={item} actualText={t('privacyNoticePage.actualPrivacyNotice')} />
    </div>
  );
};

export default PrivacyNoticePage;
