import axios from 'axios';
import { ApiClient } from '../apiClient/apiClient';
import {
  IGetFileAttributesResponse,
  ISetFileResponse
} from '../interfaces/responses/file/file.response';

const api = ApiClient.getInstance();

const getFileAttributes = async (fileType: string): Promise<IGetFileAttributesResponse> => {
  return api.get(`files/${fileType}`).then((res) => {
    return res.data;
  });
};

const setFile = async (
  file: File,
  fileType: string,
): Promise<ISetFileResponse> => {
  const fd = new FormData();
  fd.append('file', file);

  return api
    .post(`files/${fileType}`, fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((res) => {
      return res.data;
    });
};

const getFile = async (fileType: string) => {
  const header = { Accept: 'application/pdf' };
  const data = await axios
    .get(`files/file/${fileType}`, {
      responseType: 'arraybuffer',
      headers: header
    })
    .then((res) => {
      const arr = new Uint8Array(res.data);
      return arr;
    })
    .catch((error) => {
      return error;
    });

  return data;
};

export { getFileAttributes, setFile, getFile };
