/* eslint-disable import/no-anonymous-default-export */
export default {
  login: {
    loginHeader: 'Audi Gastro',
    loginText: 'Anmeldung',
    rememberPersonalData: {
      title: 'Meine Login-Daten merken',
      htmlContent:
        '<div class="font-audi-normal" style="padding: 0 20px"><p>Wenn Sie das Häkchen - nach erfolgreicher Anmeldung - abhaken, bleiben Sie 14 Tage lang auf diesem Gerät und Browser angemeldet, sodass Sie sich nicht erneut anmelden müssen. solange Sie auf den Ausmeldung Button nicht geklickt haben.</p></div>'
    },
    sessionExpired: 'Sitzung ist abgelaufen, bitte melden Sie sich erneut an!',
    gdpr: {
      text1: 'Ich habe gelesen ',
      text2: ' und ',
      text3: ' gelesen und deren Inhalt zur Kenntnis genommen.',
      Text: 'Datenschutzerklärung',
      eulaText: 'Nutzungsbedingungen'
    },
    loginButton: 'Anmeldung'
  },
  loginInputs: {
    personCode: 'Stammnummer',
    cardCode: 'Werksausweisnummer',
    pinCode: 'PIN'
  },
  enum: {
    eventType: {
      UserDeleted: 'Konto gelöscht',
      AdminLoginFailed: 'Administratoranmeldung fehlgeschlagen',
      LoginFailed: 'Anmeldung fehlgeschlagen',
      DataPolicyAccepted: 'Datenschutzerklärung akzeptiert',
      AccountLock: 'Kontosperrung',
      AccountUnlock: 'Kontoentsperrung',
      PaymentSuccessful: 'Erfolgreiche Zahlung',
      PaymentFailed: 'Zahlung fehlgeschlagen',
      UnipfrProcessSuccessful: 'Erfolgreiches Aufladen im bargeldlosen Zahlungssystems (UNIPFR)',
      UnipfrProcessFailed: 'Fehlgeschlagene Aufladung im bargeldlosen Zahlungssystems (UNIPFR)',
      All: 'Alle'
    },
    causeType: {
      SimplePay: 'SimplePay',
      Unipfr: 'Készpénzmentes fizetési rendszer (UNIPFR)',
      Automatism: 'Automatisierung',
      User: 'Benutzer',
      Administrator: 'Administrator'
    }
  },
  header: {
    home: 'Home',
    administrators: 'Administratoren',
    menu: 'Speisekare',
    privacyNotice: 'Datenschutzerklärung',
    termsOfUse: 'Nutzungsbedingungen',
    logout: 'Abmelden',
    closeText: 'Schließen',
    openText: 'Menu'
  },
  home: {
    pageText: 'Hauptseite',
    results: {
      resultCount: 'stück Treffer',
      thPage: '. Seite',
      result: 'Treffer',
      contentPerPage: 'Treffer / Seite',
      all: 'Alle'
    }
  },
  datePicker: {
    startDate: 'Anfangs Datum',
    addDate: 'Eingeben',
    endDate: 'End Datum'
  },
  pagination: {
    next: 'Nächster',
    previous: 'Vorhergehend'
  },
  common: {
    searchPlaceholder: 'Suchen',
    export: 'Exportieren',
    uploaderAdminNumber: 'Stammnummer Admin',
    date: 'Zeitpunkt',
    delete: 'Löschen',
    yes: 'Ja',
    no: 'Nein'
  },
  export: {
    success: 'Erfolgreich exportieren',
    error: 'Exportfehler'
  },
  fileDisplay: {
    fileNotFound: 'Datei nicht gefunden'
  },
  fileUpload: {
    success: 'Erfolgreiche Zahlung',
    error: 'Aufladung fehlgeschlagen. Bitte versuchen Sie es erneut'
  },
  logsByPersonCodePage: {
    personCode: 'Stammnummer',
    cardNum: 'Werksausweisnummer',
    switchOff: 'Deaktív',
    switchOn: 'Aktív'
  },
  homeTable: {
    personCode: 'Stammnummer',
    cardNum: 'Werksausweisnummer',
    event: 'Ereignis',
    reason: 'Auslöser',
    message: 'Message',
    date: 'Zeitpunkt'
  },
  menuPage: {
    menu: 'Speisekarte',
    uploadMenu: 'Speisekarte "Hochladen"',
    actualMenu: 'Speisekarte',
    noFile: 'Keine hochgeladenen Dateien!'
  },
  privacyNoticePage: {
    privacyNotice: 'Datenschutzerklärung',
    uploadPrivacyNotice: 'Datenschutzerklärung "Hochladen"',
    actualPrivacyNotice: 'Aktuelle Datenschutzerklärung'
  },
  termsOfUsePage: {
    termsOfUse: 'Nutzungsbedingungen',
    uploadTermsOfUse: 'Nutzungsbedingungen "Hochladen"',
    actualTermsOfUse: 'Aktuelle Nutzungsbedingungen'
  },
  administratorsPage: {
    administrators: 'Administratoren',
    addAdministrator: 'neuen Administrators hinzufügen',
    modal: {
      addNewAdministrator: 'neuen Administrators hinzufügen',
      noOptionsAvailable: 'Keine Option',
      personCode: 'Stammnummer',
      add: 'Hinzufügen',
      deleteAdmin: 'Administrator löschen',
      deleteAdminText1: 'Administrator sicher löchen?',
      deleteAdminText2: 'mit den Stammnummer?',
      successfulAddition: 'Administrator erfolgreich hinzugefügt',
      successfulDeletion: 'Administrator gelöscht!',
      successfulLocking: 'Benutzer erfolgreich gesperrt!',
      successfulUnlocking: 'Benutzer erfolgreich entsperrt!'
    }
  }
};
