import EventType from '../enums/event-type.enum';

export const eventList = [
  {
    value: EventType.DATA_POLICY_ACCEPTED
  },
  {
    value: EventType.LOGIN_FAILED
  },
  {
    value: EventType.ACCOUNT_LOCK
  },
  {
    value: EventType.ACCOUNT_UNLOCK
  },
  {
    value: EventType.PAYMENT_SUCCESSFUL
  },
  {
    value: EventType.PAYMENT_FAILED
  },
  {
    value: EventType.UNIPFR_PROCESS_SUCCESSFUL
  },
  {
    value: EventType.UNIPFR_PROCESS_FAILED
  },
  {
    value: EventType.USER_DELETED
  },
  {
    value: EventType.ADMIN_LOGIN_FAILED
  }
];
