import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LoginContext } from '../../../App';
import { revokeToken } from '../../../services/auth.service';

interface DrawerMenuItemProps {
  label: string;
  linkTo: string;
  isLogout: boolean;
  handleDrawerItemClick: () => void;
}

const DrawerMenuItem = ({
  label,
  linkTo,
  isLogout,
  handleDrawerItemClick
}: DrawerMenuItemProps) => {
  const { setIsLoggedIn } = useContext(LoginContext);

  const handleLogout = () => {
    revokeToken();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiresAt');
    setIsLoggedIn(false);
  };

  return (
    <>
      {isLogout ? (
        <Link to={linkTo} className="drawer-item" onClick={handleLogout}>
          {label}
        </Link>
      ) : (
        <Link to={linkTo} className="drawer-item" onClick={handleDrawerItemClick}>
          {label}
        </Link>
      )}
    </>
  );
};

export default DrawerMenuItem;
