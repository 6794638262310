import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import AudiLogo from '../../assets/icon-components/AudiLogo';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import CustomDrawer from '../CustomDrawer/CustomDrawer';
import { menuItems, menuItemTypes } from '../../utils/menu.config';
import './Header.scss';
import { revokeToken } from '../../services/auth.service';

const Header = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  let navigate = useNavigate();

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 900;

  const logout = () => {
    revokeToken();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expiresAt');
    navigate('/login');
  };

  const { t } = useTranslation();

  if (isMobile) {
    return (
      <div className="header-container">
        <Container>
          <div className="mobile-header">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <CustomDrawer />
              </Grid>
              <Grid item>
                <div className="audi-logo-container">
                  <Link to="/home">
                    <AudiLogo />
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="header-container">
        <Container sx={{ my: 2 }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <div className="audi-logo-container">
                <Link to="/home">
                  <AudiLogo />
                </Link>
              </div>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent={'right'} spacing={2}>
                {menuItems.map((item, index) => (
                  <Grid item key={'menu-item-' + index}>
                    {item.type === menuItemTypes.logout ? (
                      <NavLink to="#" onClick={logout} className="nav-item">
                        {t(item.label)}
                      </NavLink>
                    ) : (
                      <NavLink
                        to={item.linkTo}
                        className={({ isActive }) => `${isActive ? 'underline' : ''} nav-item`}>
                        {t(item.label)}
                      </NavLink>
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
};

export default Header;
