import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-small.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-large.svg';
import AddAdministratorModal from '../../components/Administrators/AddAdministratorModal/AddAdministratorModal';
import AdministratorsTable from '../../components/Administrators/AdministratorsTable/AdministratorsTable';
import DeleteAdministratorModal from '../../components/Administrators/DeleteAdministratorModal/DeleteAdministratorModal';
import ResultsPanel from '../../components/ResultsPanel/ResultsPanel';
import TableFooter from '../../components/TableFooter/TableFooter';
import CustomCard from '../../components/UI/CustomCard/CustomCard';
import CustomSearch from '../../components/UI/CustomSearch/CustomSearch';
import { IGetAdminsByPersonCodeRequest } from '../../interfaces/requests/users/users.request';
import { IGetMetaResponse } from '../../interfaces/responses/log/log.response';
import { IUsersResponse } from '../../interfaces/responses/users/users.response';
import { getAdmins } from '../../services/users.service';
import './AdministratorsPage.scss';

const AdministratorsPage = () => {
  const [searchText, setSearchText] = useState('');
  const [resultsPerPage, setResultsPerPage] = useState<null | number>(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAddAdminModalVisible, setIsAddAdminModalVisible] = useState(false);
  const [isDeleteAdminModalVisible, setIsDeleteAdminModalVisible] = useState(false);
  const [selectedPersonCode, setSelectedPersonCode] = useState('');
  const [admins, setAdmins] = useState<IUsersResponse[]>([]);
  const resultsPerPageOtions = [25, 50, 100, null];
  const [meta, setMeta] = useState<IGetMetaResponse>();

  const { t } = useTranslation();

  const getAdminData = async () => {
    let limit = 1000;
    if (resultsPerPage !== null) {
      limit = resultsPerPage;
    } else if (meta?.totalItems) {
      limit = meta.totalItems;
    }

    let getAdminsRequest: IGetAdminsByPersonCodeRequest = {
      personCode: searchText,
      limit: limit,
      page: currentPage
    };

    if (searchText === '') {
      getAdminsRequest = { ...getAdminsRequest, personCode: 'all' };
    }

    const adminData = await getAdmins(getAdminsRequest);
    setAdmins(adminData.items);
    setMeta(adminData.meta);
  };

  useEffect(() => {
    getAdminData();
  }, [searchText, resultsPerPage, currentPage]);

  const openAddAdministratorModal = () => {
    setIsAddAdminModalVisible(true);
  };

  const openDeleteModal = (personCode: string) => {
    setSelectedPersonCode(personCode);
    setIsDeleteAdminModalVisible(true);
  };

  return (
    <div className="administrators-page">
      <h2 className="font-audi-extended-bold header-text">
        {t('administratorsPage.administrators')}
      </h2>

      <div className="card-container">
        <CustomCard
          icon={<UserIcon />}
          text={t('administratorsPage.addAdministrator')}
          customFunction={openAddAdministratorModal}
        />
      </div>
      <div className="search-container">
        <CustomSearch
          value={searchText}
          setValue={setSearchText}
          debounceTime={500}
          placeholderText={t('common.searchPlaceholder')}
          icon={<SearchIcon />}
        />
      </div>
      {meta && (
        <ResultsPanel
          resultsNumber={meta.totalItems}
          currentPage={meta.currentPage}
          setResultsPerPage={setResultsPerPage}
          resultsPerPageOptions={resultsPerPageOtions}
        />
      )}
      <AdministratorsTable items={admins} customFunction={openDeleteModal} />
      {meta && (
        <TableFooter
          resultsNumber={meta.totalItems}
          currentPage={meta.currentPage}
          pageCount={meta.totalPages}
          setCurrentPage={setCurrentPage}
        />
      )}
      <AddAdministratorModal
        isVisible={isAddAdminModalVisible}
        closeModalHandler={() => setIsAddAdminModalVisible(false)}
        getAdminData={getAdminData}
      />
      <DeleteAdministratorModal
        personCode={selectedPersonCode}
        isVisible={isDeleteAdminModalVisible}
        closeModalHandler={() => setIsDeleteAdminModalVisible(false)}
        getAdminData={getAdminData}
      />
    </div>
  );
};

export default AdministratorsPage;
