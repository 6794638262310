import { useState } from 'react';
import { TextField } from '@mui/material';
import './CustomSearch.scss';

interface CustomSearchProps {
  value: string;
  setValue: (value: string) => void;
  placeholderText: string;
  icon: React.ReactElement;
  debounceTime?: number;
}

const CustomSearch = ({
  value,
  setValue,
  debounceTime = 0,
  placeholderText,
  icon
}: CustomSearchProps) => {
  const [localValue, setLocalValue] = useState(value);

  let debounceInput = setTimeout(() => {
    setValue(localValue);
  }, debounceTime);

  const handleTextChange = (newValue: string) => {
    clearTimeout(debounceInput);
    setLocalValue(newValue);
  };

  return (
    <>
      <div className={`search-container ${value === '' ? 'empty' : ''}`}>
        <TextField
          className="custom-input"
          value={localValue}
          onChange={(e) => handleTextChange(e.target.value)}
          label={placeholderText}
          variant="standard"
        />
        <div className="search-icon">{icon}</div>
      </div>
    </>
  );
};

export default CustomSearch;
