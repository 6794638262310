import { styled } from '@mui/material/styles';
import usePagination from '@mui/material/usePagination';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './CustomPagination.scss';

interface CustomPaginationProps {
  pageCount: number;
  setCurrentPage: (currentPage: number) => void;
}

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex'
});

export default function CustomPagination({ pageCount, setCurrentPage }: CustomPaginationProps) {
  const [selectedPage, setSelectedPage] = useState(1);

  const setPage = (event: any, page: number) => {
    setSelectedPage(page);
    setCurrentPage(page);
  };

  let { items } = usePagination({
    count: pageCount,
    page: selectedPage,
    siblingCount: 1,
    boundaryCount: 1,
    onChange: setPage
  });

  useEffect(() => {
    setPage(null, 1);
  }, [pageCount]);

  const { t } = useTranslation();

  return (
    <nav className="pagination-container">
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = (
              <div className="page-select-button not-clickable">
                <div className="page-button-text">...</div>
              </div>
            );
          } else if (type === 'page') {
            children = (
              <button
                type="button"
                className={`page-select-button ${selected ? 'selected' : ''}`}
                {...item}>
                <div className="page-button-text">{page}</div>
              </button>
            );
          } else {
            children = (
              <button type="button" className="next-prev-button font-audi-bold" {...item}>
                {type === 'previous' && <div className="next-prev-arrow prev-arrow">{'<'}</div>}
                {type === 'previous' ? t('pagination.previous') : t('pagination.next')}
                {type === 'next' && <div className="next-prev-arrow next-arrow">{'>'}</div>}
              </button>
            );
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
}
