import { useTranslation } from 'react-i18next';
import { ReactComponent as ExportIcon } from '../../../assets/icons/external-link-small.svg';
import './ExportButton.scss';

interface ExportButtonProps {
  exportData: () => void;
}

const ExportButton = ({ exportData }: ExportButtonProps) => {
  const { t } = useTranslation();
  return (
    <>
      <button className="export-button" onClick={exportData} type="submit">
        <ExportIcon /> {t('common.export')}
      </button>
    </>
  );
};

export default ExportButton;
