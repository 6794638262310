import { ApiClient } from '../apiClient/apiClient';
import { IAdminsResponse, IUsersResponse } from '../interfaces/responses/users/users.response';
import {
  IUpdateAdminRequest,
  IGetUserByPersonCodeRequest,
  IGetAdminsByPersonCodeRequest
} from '../interfaces/requests/users/users.request';

const api = ApiClient.getInstance();

const getUsers = async (): Promise<IUsersResponse[]> => {
  const response = await api.get('users').then((res) => {
    return res.data;
  });

  return response as IUsersResponse[];
};

const getAdmins = async (
  getAdminsRequest: IGetAdminsByPersonCodeRequest
): Promise<IAdminsResponse> => {
  const response = await api.get(`users/admins/${getAdminsRequest.personCode}`).then((res) => {
    return res.data;
  });

  return response as IAdminsResponse;
};

const getUserByPersonCode = async (
  getUserByPersonCodeRequest: IGetUserByPersonCodeRequest
): Promise<IUsersResponse> => {
  const { data } = await api.get(`users/${getUserByPersonCodeRequest.personCode}`);

  return data as IUsersResponse;
};

const updateAdmin = async (updateAdminRequest: IUpdateAdminRequest): Promise<boolean> => {
  const response = await api.put('users', updateAdminRequest);
  if (response.status === 200) return true;
  return false;
};

const lockUser = async (personCode: string, adminPersonCode: string) => {
  const response = await api.put(`users/lock/${personCode}/${adminPersonCode}`);

  if (response.status === 200) return true

  return false;
}
const unlockUser = async (personCode: string, adminPersonCode: string) => {

  const response = await api.put(`users/unlock/${personCode}/${adminPersonCode}`);

  if (response.status === 200) return true;

  return false;
}



export { getUsers, getUserByPersonCode, getAdmins, updateAdmin, lockUser, unlockUser };
