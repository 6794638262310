import axios from 'axios';
import { saveAs } from "file-saver";
import { ApiClient } from '../apiClient/apiClient';
import {
  IExportLogsRequest,
  IGetAllLogsRequest,
  IGetLogsByPersonCodeRequest
} from '../interfaces/requests/log/log.request';
import {
  IGetAllLogItemsResponse,
  IGetAllLogsResponse,
  IGetLogsByPersonCodeResponse
} from '../interfaces/responses/log/log.response';

const api = ApiClient.getInstance();


const getLogsByFilter = async (
  search: string,
  getLogsByFilterRequest: IGetAllLogsRequest
): Promise<IGetAllLogsResponse> => {
  const url = 'logs/search'
  const query = `?search=${search}&dateFrom=${getLogsByFilterRequest.dateFrom}&dateTo=${getLogsByFilterRequest.dateTo}&eventType=${getLogsByFilterRequest.eventType}&page=${getLogsByFilterRequest.page}&limit=${getLogsByFilterRequest.limit}`;

  const response = await api.post(url + query, {}).then((res) => {
    res.data.items = setCurrentTimeZone(res.data.items);
    return res.data;
  });
  return response as IGetAllLogsResponse;
};

const getLogsByPersonCode = async (
  personCode: string | undefined,
  getLogsByPersonCodeRequest: IGetLogsByPersonCodeRequest
): Promise<IGetLogsByPersonCodeResponse> => {
  if (personCode) {
    const response = await api
      .get(`logs/${personCode}`, getLogsByPersonCodeRequest)
      .then((res) => {
        res.data.items = setCurrentTimeZone(res.data.items);
        return res.data;
      });
    return response as IGetLogsByPersonCodeResponse;
  }
  return Promise.reject();
};

const exportLogs = async (exportLogsRequest: IExportLogsRequest, personCode: string = '') => {
  
  //get current time zone
  const date = new Date();
  const timeZoneOffset = date.getTimezoneOffset();
  const url = `logs/search/export`;
  const query = `?search=${exportLogsRequest.search}&timeZoneOffset=${timeZoneOffset}&dateFrom=${exportLogsRequest.dateFrom}&dateTo=${exportLogsRequest.dateTo}&eventType=${exportLogsRequest.eventType}&personCode=${personCode}`;
  const headers = {
    'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
  }

  // Axios interceptor breaks default functionality but it is harder to overcome then use the default instance
  await axios.get(url + query, { headers: headers, responseType: 'arraybuffer' }).then((res) => {
    const filename = JSON.stringify(res.headers);

    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, filename.slice(45, filename.indexOf(',') - 1));
  });
};

const setCurrentTimeZone = (data: IGetAllLogItemsResponse[]): IGetAllLogItemsResponse[] => {
  return data.map((log) => {
    const date = new Date(log.createdAt);
    date.setUTCHours(date.getHours());
    log.createdAt = date.toISOString();
    return log;
  });
}

export { getLogsByFilter, getLogsByPersonCode, exportLogs };
