import CustomPagination from '../UI/CustomPagination/CustomPagination';
import ResultsInfo from '../ResultsInfo/ResultsInfo';
import ExportButton from '../UI/ExportButton/ExportButton';
import './TableFooter.scss';

interface TableFooterProps {
  resultsNumber: number;
  currentPage: number;
  pageCount: number;
  setCurrentPage: (currentPage: number) => void;
  exportData?: () => void;
}

const TableFooter = ({
  resultsNumber,
  currentPage,
  pageCount,
  setCurrentPage,
  exportData
}: TableFooterProps) => {
  return (
    <div className="table-footer-container">
      <div className="item-left">
        <ResultsInfo resultsNumber={resultsNumber} currentPage={currentPage} />
      </div>
      <div className="item-center">
        <CustomPagination pageCount={pageCount} setCurrentPage={setCurrentPage} />
      </div>
      <div className="item-right">{exportData && <ExportButton exportData={exportData} />}</div>
    </div>
  );
};

export default TableFooter;
