import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendar-events-large.svg';
import { useTranslation } from 'react-i18next';
import { dateFormats } from '../../../utils/dateFormats.config';
import './CustomDatePicker.scss';

interface CustomDatePickerProps {
  label: string;
  datePickerValueChange: (date: string) => void;
  value: null | string;
}

const CustomDatePicker = ({ label, datePickerValueChange, value }: CustomDatePickerProps) => {
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className={`date-picker-container ${
          !datePickerOpen && !value ? 'color-gray' : 'color-black'
        }`}>
        <div className="date-picker-header-text color-gray">{label}</div>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            className={`${datePickerOpen || value ? 'date-picker-open' : ''}`}
            open={datePickerOpen}
            onOpen={() => setDatePickerOpen(true)}
            onClose={() => setDatePickerOpen(false)}
            inputFormat={dateFormats[i18n.language as keyof typeof dateFormats].defaultDateFormat}
            InputAdornmentProps={{ position: 'start' }}
            components={{
              OpenPickerIcon: CalendarIcon
            }}
            mask={dateFormats[i18n.language as keyof typeof dateFormats].mask}
            value={value}
            onChange={(newValue) => {
              if (newValue) datePickerValueChange(newValue);
            }}
            renderInput={(params) => (
              <>
                <Button
                  className={`date-picker-button ${
                    !datePickerOpen && !value ? 'visible' : 'hidden'
                  }`}
                  variant="text"
                  onClick={() => setDatePickerOpen(true)}>
                  {t('datePicker.addDate')}
                </Button>
                <TextField
                  variant="standard"
                  className={`${!datePickerOpen && !value ? 'hidden-date-picker-placeholder' : ''}`}
                  {...params}
                />
              </>
            )}
          />
        </LocalizationProvider>
      </div>
    </>
  );
};

export default CustomDatePicker;
