import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu-icon.svg';
import FileUploadWithTable from '../../components/FileUploadWithTable/FileUploadWithTable';
import { SnackbarContext } from '../../components/SnackbarAlert/SnackbarAlert';
import CustomCard from '../../components/UI/CustomCard/CustomCard';
import FileType from '../../enums/file-type.enum';
import { IGetFileAttributesResponse } from '../../interfaces/responses/file/file.response';
import { getFileAttributes, setFile } from '../../services/files.service';
import './MenuPage.scss';

const MenuPage = () => {
  const [item, setItem] = useState<IGetFileAttributesResponse | null>(null);

  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const uploadFile = async (file: File) => {
    try {
      await setFile(file, FileType.MENU);
      setSnack({
        isError: false,
        open: true,
        message: t('fileUpload.success'),
      })
    } catch (error) {
      setSnack({
        isError: true,
        open: true,
        message: t('fileUpload.error'),
      })
    }
    getData();
  };

  const getData = async () => {
    setItem(await getFileAttributes(FileType.MENU));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="menu-page">
      <h2 className="font-audi-extended-bold header-text">{t('menuPage.menu')}</h2>
      <div className="card-container">
        <CustomCard icon={<MenuIcon />} text={t('menuPage.uploadMenu')} uploadFile={uploadFile} />
      </div>
      <FileUploadWithTable file={item} actualText={t('menuPage.actualMenu')} />
    </div>
  );
};

export default MenuPage;
