import { useTranslation } from 'react-i18next';
import { ReactComponent as PdfIcon } from '../../assets/icons/document-pdf-large.svg';
import CustomCard from '../../components/UI/CustomCard/CustomCard';
import { getFileAttributes, setFile } from '../../services/files.service';
import './TermsOfUsePage.scss';
import { useContext, useEffect, useState } from 'react';
import FileUploadWithTable from '../../components/FileUploadWithTable/FileUploadWithTable';
import { IGetFileAttributesResponse } from '../../interfaces/responses/file/file.response';
import FileType from '../../enums/file-type.enum';
import { SnackbarContext } from '../../components/SnackbarAlert/SnackbarAlert';

const TermsOfUsePage = () => {
  const [item, setItem] = useState<IGetFileAttributesResponse | null>(null);

  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const uploadFile = async (file: File) => {
    try {
      await setFile(file, FileType.TERMS_AND_CONDITIONS);
      setSnack({
        isError: false,
        open: true,
        message: t('fileUpload.success')
      });
    } catch (error) {
      setSnack({
        isError: true,
        open: true,
        message: t('fileUpload.error')
      });
    }
    getData();
  };

  const getData = async () => {
    setItem(await getFileAttributes(FileType.TERMS_AND_CONDITIONS));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="terms-of-use-page">
      <h2 className="font-audi-extended-bold header-text">{t('termsOfUsePage.termsOfUse')}</h2>
      <div className="card-container">
        <CustomCard
          icon={<PdfIcon />}
          text={t('termsOfUsePage.uploadTermsOfUse')}
          uploadFile={uploadFile}
        />
      </div>
      <FileUploadWithTable file={item} actualText={t('termsOfUsePage.actualTermsOfUse')} />
    </div>
  );
};

export default TermsOfUsePage;
