import axios, { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { IJWTPayload } from '../interfaces/models/jwt.payload.model';
import { refreshToken } from '../services/auth.service';

export class ApiClient {
  private static instance: ApiClient;

  private readonly baseUrl = process.env.REACT_APP_API_BASE_URL;
  private isRefreshInProgress = false;

  private constructor() {
    axios.defaults.baseURL = this.baseUrl;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Methods'] =
      'GET, POST, PUT, DELETE, PATCH, OPTIONS';

    axios.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const token = localStorage.getItem('accessToken')

        if (token) {
          config.headers = config.headers ?? {};
          config.headers['Authorization'] = 'Bearer ' + token

          const payload: IJWTPayload = jwt_decode(token);
          const exp = payload.exp * 1000;

          if (moment().utc().isAfter(moment(exp).utc().subtract('1', 'minute')) && !this.isRefreshInProgress) {
            this.isRefreshInProgress = true;
            const response = await refreshToken();
            const accessToken = response.data?.accessToken

            if (accessToken) {
              config.headers['Authorization'] = 'Bearer ' + response.data.accessToken;
              let expiresAt = moment().add(15, 'm').toISOString();
              localStorage.setItem('accessToken', accessToken);
              localStorage.setItem('expiresAt', expiresAt);
              this.isRefreshInProgress = false;
            } else {
              config.headers['Authorization'] = '';
              localStorage.removeItem('accessToken');
              localStorage.removeItem('expiresAt');
              localStorage.setItem('isLogoutInProgress', 'true')
              this.isRefreshInProgress = false;
              window.location.reload();
            }
          }
        }

        return config
      },
      (error) => {
        Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      response => {
        return response
      },
      (error) => {
        if (error.response.status === 401) {
          const token = localStorage.getItem('accessToken')
          if (token) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('expiresAt');
          }

          localStorage.setItem('isLogoutInProgress', 'true')
          window.location.reload();
          return Promise.reject(error)
        }

        return Promise.reject(error)
      }
    )
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  public get(url: string, params?: any, responseType?: any) {
    if (params) return axios.get(url, { params });
    if (responseType) return axios.get(url, { params, responseType });
    return axios.get(url);
  }

  public post(url: string, data: any, config?: any) {
    if (config) return axios.post(url, data, config);
    return axios.post(url, data);
  }

  public put(url: string, data?: any) {
    return axios.put(url, data);
  }

  public delete(url: string, data?: any) {
    return axios.delete(url, data);
  }

  public deleteWithParams(url: string, data: any) {
    return axios.delete(url, { data });
  }
}
