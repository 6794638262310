/* eslint-disable import/no-anonymous-default-export */
export default {
  login: {
    loginHeader: 'Audi Gastro',
    loginText: 'Bejelentkezés',
    rememberPersonalData: {
      title: 'Belépési adataim megjegyzése',
      htmlContent:
        '<div class="font-audi-normal" style="padding: 0 20px"><p>A jelölőnégyzet bepipálásával – sikeres belépést követően – az adott eszközön és böngészőben 14 napig bejelentkezve maradsz, így nem szükséges újra bejelentkezned, mindaddig míg a kijelentkezés gombra nem kattintasz!</p></div>'
    },
    sessionExpired: 'Munkamenet lejárt, kérjük jelentkezzen be újra!',
    gdpr: {
      text1: 'Az',
      gdprText: 'Adatvédelmi Tájékoztatót',
      text2: ' és a',
      eulaText: 'Felhasználási feltételeket',
      text3: 'elolvastam, azok tartalmát tudomásul vettem.'
    },
    loginButton: 'Belépés'
  },
  loginInputs: {
    personCode: 'Törzsszám',
    cardCode: 'Kártyaszám',
    pinCode: 'PIN'
  },
  enum: {
    eventType: {
      UserDeleted: 'Fiók törölve',
      AdminLoginFailed: 'Sikertelen admin bejelentkezés',
      LoginFailed: 'Sikertelen bejelentkezés',
      DataPolicyAccepted: 'Adatvédelmi tájékoztató elfogadva',
      AccountLock: 'Fiók zárolva',
      AccountUnlock: 'Fiók feloldva',
      PaymentSuccessful: 'Sikeres fizetés',
      PaymentFailed: 'Sikertelen fizetés',
      UnipfrProcessSuccessful: 'Sikeres UniPFR felértékelés',
      UnipfrProcessFailed: 'Sikertelen UniPFR felértékelés',
      All: 'Összes'
    },
    causeType: {
      SimplePay: 'SimplePay',
      UniPFR: 'UniPFR',
      Automatism: 'Automatizmus',
      User: 'Felhasználó',
      Administrator: 'Admin'
    }
  },
  header: {
    home: 'Főoldal',
    administrators: 'Adminisztrátorok',
    menu: 'Étlap',
    privacyNotice: 'Adatvédelmi tájékoztató',
    termsOfUse: 'Felhasználási feltételek',
    logout: 'Kijelentkezés',
    closeText: 'Bezár',
    openText: 'Menu'
  },
  home: {
    pageText: 'Főoldal',
    results: {
      resultCount: 'db találat',
      thPage: '. oldal',
      result: 'Találat',
      contentPerPage: 'találat / oldal',
      all: 'Összes'
    }
  },
  datePicker: {
    startDate: 'Kezdő dátum',
    addDate: 'Megadás',
    endDate: 'Vég dátum'
  },
  pagination: {
    next: 'Következő',
    previous: 'Előző'
  },
  common: {
    searchPlaceholder: 'Keresés',
    export: 'Exportálás',
    uploaderAdminNumber: 'Feltöltő Admin törzsszáma',
    date: 'Időpont',
    delete: 'Törlés',
    yes: 'Igen',
    no: 'Nem'
  },
  export: {
    success: 'Sikeres exportálás',
    error: 'Hiba az exportálás során'
  },
  fileDisplay: {
    fileNotFound: 'A fájl nem található'
  },
  fileUpload: {
    success: 'Sikeres feltöltés',
    error: 'Hiba a feltöltés során. Próbáld újra később.'
  },
  logsByPersonCodePage: {
    personCode: 'Törzsszám',
    cardCode: 'Kártyaszám',
    switchOff: 'Zárolva',
    switchOn: 'Aktív'
  },
  homeTable: {
    personCode: 'Törzsszám',
    cardNum: 'Kártyaszám',
    event: 'Esemény',
    reason: 'Kiváltó',
    message: 'Üzenet',
    date: 'Időpont'
  },
  menuPage: {
    menu: 'Étlap',
    uploadMenu: 'Étlap feltöltése',
    actualMenu: 'Aktuális étlap',
    noFile: 'Nincs feltöltött fájl!'
  },
  privacyNoticePage: {
    privacyNotice: 'Adatvédelmi tájékoztató',
    uploadPrivacyNotice: 'Adatvédelmi tájékoztató feltöltése',
    actualPrivacyNotice: 'Aktuális adatvédelmi tájékoztató'
  },
  termsOfUsePage: {
    termsOfUse: 'Felhasználási feltételek',
    uploadTermsOfUse: 'Felhasználási feltételek feltöltése',
    actualTermsOfUse: 'Aktuális felhasználási feltételek'
  },
  administratorsPage: {
    administrators: 'Adminisztrátorok',
    addAdministrator: 'Új adminisztrátor hozzáadása',
    modal: {
      addNewAdministrator: 'Új adminisztrátor hozzáadása',
      noOptionsAvailable: 'Nincs opció',
      personCode: 'Törzsszám',
      add: 'Hozzáad',
      deleteAdmin: 'Adminisztrátor törlése',
      deleteAdminText1: 'Biztosan törlöd az Adminisztrátorok közül a',
      deleteAdminText2: 'törzsszámú kollégát?',
      successfulAddition: 'Admin sikeresen hozzáadva!',
      successfulDeletion: 'Admin sikeresen törölve!',
      successfulLocking: 'Felhasználó sikeresen zárolva!',
      successfulUnlocking: 'Felhasználó sikeresen feloldva!'
    }
  }
};
