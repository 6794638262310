import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IGetFileAttributesResponse } from '../../interfaces/responses/file/file.response';
import '../HomeTable/HomeTable.scss';

interface DataTableProps {
  actualText: string;
  file: IGetFileAttributesResponse | null;
}

export default function FileUploadWithTable({ file, actualText }: DataTableProps) {
  const { t } = useTranslation();

  return (
    <TableContainer className="custom-table">
      <Table>
        <TableHead>
          <TableRow className="header-text">
            <TableCell align="left">{actualText}</TableCell>
            <TableCell align="left">{t('common.uploaderAdminNumber')}</TableCell>
            <TableCell align="right">{t('common.date')}</TableCell>
          </TableRow>
        </TableHead>
        {!file && (
          <TableBody>
            <TableRow>
              <TableCell>{t('menuPage.noFile')}</TableCell>
            </TableRow>
          </TableBody>
        )}
        {file && (
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className={`table-body ${file.type}`}>
              <TableCell align="left">{file.name}</TableCell>
              <TableCell align="left">{file.uploaderPersonCode}</TableCell>
              <TableCell align="right">
                { file.uploadedAt ? moment(file.uploadedAt).format('YYYY.MM.DD. HH:mm:ss'): ''}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
