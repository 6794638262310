import { Container } from '@mui/system';
import { FunctionComponent } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Header from '../components/Header/Header';
import AdministratorsPage from '../pages/AdministratorsPage/AdministratorsPage';
import LogsByPersonCodePage from '../pages/LogsByPersonCodePage/LogsByPersonCodePage';
import HomePage from '../pages/HomePage/HomePage';
import LoginPage from '../pages/LoginPage/LoginPage';
import MenuPage from '../pages/MenuPage/MenuPage';
import PrivacyNoticePage from '../pages/PrivacyNoticePage/PrivacyNoticePage';
import TermsOfUsePage from '../pages/TermsOfUsePage/TermsOfUsePage';
import './Router.scss';
import { PrivateRoutes } from './PrivateRoutes';

interface RouterProps {
  isLoggedIn: boolean;
}

const Router: FunctionComponent<RouterProps> = ({ isLoggedIn }) => {
  const location = useLocation();

  return (
    <>
      {isLoggedIn && localStorage.getItem('accessToken') && <Header />}
      <TransitionGroup>
        <CSSTransition key={location.key} classNames="page" timeout={300}>
          <div>
            <Container
              className={`${
                isLoggedIn && localStorage.getItem('accessToken') ? 'content' : ''
              } page-content-container`}>
              <Routes>
                <>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/" element={<PrivateRoutes />}>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/administrators" element={<AdministratorsPage />} />
                    <Route path="/menu" element={<MenuPage />} />
                    <Route path="/privacy-notice" element={<PrivacyNoticePage />} />
                    <Route path="/terms-of-use" element={<TermsOfUsePage />} />
                    <Route
                      path="/events-by-person-code/:personCode"
                      element={<LogsByPersonCodePage />}
                    />
                    <Route path="/" element={<Navigate to='/home' />} />
                  </Route>
                </>
              </Routes>
            </Container>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default Router;
