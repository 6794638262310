import { Grid } from '@mui/material';
import { FunctionComponent, memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGetUserByPersonCodeRequest,
  IUpdateAdminRequest
} from '../../../interfaces/requests/users/users.request';
import { getUserByPersonCode, updateAdmin } from '../../../services/users.service';
import { SnackbarContext } from '../../SnackbarAlert/SnackbarAlert';
import CustomButton from '../../UI/CustomButton/CustomButton';
import Modal from '../../UI/Modal/Modal';
import './DeleteAdministratorModal.scss';

interface CustomModalProps {
  isVisible: boolean;
  closeModalHandler: () => void;
  personCode: string;
  getAdminData: () => void;
}

const DeleteAdministratorModal: FunctionComponent<CustomModalProps> = memo(
  ({ isVisible, closeModalHandler, personCode, getAdminData }) => {
    const { t } = useTranslation();
    const [modalClosing, setModalClosing] = useState(false);
    const { setSnack } = useContext(SnackbarContext);

    const modalClose = () => {
      setModalClosing(true);
      setTimeout(() => {
        setModalClosing(false);
      }, 500);
    };

    const handleDelete = async () => {
      const getUserByPersonCodeRequest: IGetUserByPersonCodeRequest = {
        personCode
      };

      const user = await getUserByPersonCode(getUserByPersonCodeRequest);

      const updateAdminRequest: IUpdateAdminRequest = {
        id: user.id,
        personCode: user.personCode,
        cardCode: user.cardCode,
        termsAcceptedAt: user.termsAcceptedAt,
        blockedUntil: user.blockedUntil,
        loginAttempts: user.loginAttempts,
        isAdmin: false,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt
      };

      if (await updateAdmin(updateAdminRequest)) {
        setSnack({
          isError: false,
          open: true,
          message: t('administratorsPage.modal.successfulDeletion')
        });
        getAdminData();
      } else {
        setSnack({
          isError: false,
          open: true,
          message: t('general.error')
        });
      }

      modalClose();
    };

    return (
      <Modal
        isVisible={isVisible}
        closeModalHandler={closeModalHandler}
        modalClosing={modalClosing}
        customClass="delete-admin-modal">
        <Grid container direction="column" spacing={2} className="delete-admin-modal-content">
          <Grid item>
            <div className="align-middle font-audi-extended-bold">
              <h2>{t('administratorsPage.modal.deleteAdmin')}</h2>
            </div>
          </Grid>
          <Grid item className="person-number-input-container delete-text">
            <div className="align-middle ">
              {t('administratorsPage.modal.deleteAdminText1')}{' '}
              <div className="font-audi-bold">{personCode}</div>{' '}
              {t('administratorsPage.modal.deleteAdminText2')}
            </div>
          </Grid>
          <Grid item>
            <div className="confirm-buttons">
              <div className="button-transparent-background">
                <CustomButton text={t('common.yes')} customFunction={handleDelete} />
              </div>
              <CustomButton text={t('common.no')} customFunction={modalClose} />
            </div>
          </Grid>
        </Grid>
      </Modal>
    );
  }
);

export default memo(DeleteAdministratorModal);
