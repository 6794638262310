import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import jwt_decode from 'jwt-decode';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';
import { IJWTPayload } from '../../../interfaces/models/jwt.payload.model';
import '../../HomeTable/HomeTable.scss';
import './AdministratorsTable.scss';

interface DataTableProps {
  items: {
    id: string;
    personCode: string;
  }[];
  customFunction: (personCode: string) => void;
}

export default function AdministratorsTable({ items, customFunction }: DataTableProps) {
  const { t } = useTranslation();
  const token: string = useMemo(() => localStorage.getItem('accessToken')!, []);
  const payload: IJWTPayload = jwt_decode(token);
  const personalPersonCode = payload.personCode;

  let navigate = useNavigate();

  const navigateTo = (personCode: string) => {
    navigate('/events-by-person-code/' + personCode);
  };

  function createData(id: string, personCode: string) {
    return { id, personCode };
  }

  const rows = items.map((item) => createData(item.id, item.personCode));

  return (
    <TableContainer className="custom-table">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow className="header-text">
            <TableCell align="left">{t('homeTable.personCode')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="add-padding">
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className={`table-body cursor-pointer`}>
              <TableCell
                align="left"
                className="cursor-pointer"
                onClick={() => navigateTo(row.personCode)}>
                {row.personCode}
              </TableCell>
              {row.personCode !== personalPersonCode && (
                <TableCell
                  align="right"
                  className="cursor-pointer"
                  onClick={() => customFunction(row.personCode)}>
                  <div className="trash-icon-container">
                    <TrashIcon />
                  </div>
                  <div className="delete-text">{t('common.delete')}</div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
