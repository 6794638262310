export const menuItemTypes = {
  home: 'home',
  administrators: 'administrators',
  menu: 'menu',
  privacyNotice: 'privacyNotice',
  termsOfUse: 'termsOfUse',
  logout: 'logout'
};

export const menuItems = [
  {
    label: 'header.home',
    linkTo: '/home',
    type: menuItemTypes.home
  },
  {
    label: 'header.administrators',
    linkTo: '/administrators',
    type: menuItemTypes.administrators
  },
  {
    label: 'header.menu',
    linkTo: '/menu',
    type: menuItemTypes.menu
  },
  {
    label: 'header.privacyNotice',
    linkTo: '/privacy-notice',
    type: menuItemTypes.privacyNotice
  },
  {
    label: 'header.termsOfUse',
    linkTo: '/terms-of-use',
    type: menuItemTypes.termsOfUse
  },
  {
    label: 'header.logout',
    linkTo: '#',
    type: menuItemTypes.logout
  }
];
