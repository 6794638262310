import { ReactElement, useRef } from 'react';
import './CustomCard.scss';

interface CustomCardProps {
  text: string;
  icon: ReactElement<any, any>;
  uploadFile?: (files: File) => void;
  customFunction?: () => void;
}

const CustomCard = ({ text, icon, uploadFile, customFunction }: CustomCardProps) => {
  let inputFileRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    if (customFunction) {
      customFunction();
    } else {
      inputFileRef.current?.click();
    }
  };

  const fileChangedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && uploadFile) {
      uploadFile(files[0]);
    }
  };

  return (
    <>
      <div className="custom-card card-container" onClick={onButtonClick}>
        <div className="card-content">
          <div className="icon-container">{icon}</div>
          <div className="text-container font-audi-bold">{text}</div>
        </div>
        {!customFunction && (
          <div className="hidden-input-container">
            <label>
              <input
                type="file"
                id="file"
                ref={inputFileRef}
                onChange={(e) => fileChangedHandler(e)}
              />
              Input
            </label>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomCard;
