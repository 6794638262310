import moment from 'moment';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomeTable from '../../components/HomeTable/HomeTable';
import ResultsPanel from '../../components/ResultsPanel/ResultsPanel';
import SearchWithDatePicker from '../../components/SearchWithDatePicker/SearchWithDatePicker';
import { SnackbarContext } from '../../components/SnackbarAlert/SnackbarAlert';
import TableFooter from '../../components/TableFooter/TableFooter';
import EventType from '../../enums/event-type.enum';
import { IExportLogsRequest, IGetAllLogsRequest } from '../../interfaces/requests/log/log.request';
import {
  IGetAllLogItemsResponse,
  IGetMetaResponse
} from '../../interfaces/responses/log/log.response';
import { exportLogs, getLogsByFilter } from '../../services/log.service';

interface HomePageProps {}

const HomePage: FunctionComponent<HomePageProps> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState<null | number>(25);
  const [filterBy, setFilterBy] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(14, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [searchText, setSearchText] = useState('');
  const [items, setItems] = useState<IGetAllLogItemsResponse[]>();
  const [meta, setMeta] = useState<IGetMetaResponse>();
  const resultsPerPageOtions = [25, 50, 100, null];

  const { t } = useTranslation();
  const { setSnack } = useContext(SnackbarContext);

  const getData = async (
    searchText: string,
    filterBy: string,
    startDate: string | null,
    endDate: string | null
  ) => {
    let limit = 1000;
    if (resultsPerPage !== null) {
      limit = resultsPerPage;
    } else if (meta?.totalItems) {
      limit = meta.totalItems;
    }

    const getAllLogsRequest: IGetAllLogsRequest = {
      dateFrom: startDate,
      dateTo: endDate,
      limit: limit,
      page: currentPage,
      eventType: filterBy as EventType
    };

    const data = await getLogsByFilter(searchText, getAllLogsRequest);
    setItems(data.items);
    const metaData = { ...data.meta };
    setMeta(metaData);
  };

  useEffect(() => {
    getData(searchText, filterBy, startDate, endDate);
  }, [currentPage, filterBy, startDate, endDate, searchText]);

  useEffect(() => {
    setCurrentPage(1); // All filtering must result in a new table
    getData(searchText, filterBy, startDate, endDate);
  }, [resultsPerPage]);

  const exportData = async () => {
    let exportLogsRequest: IExportLogsRequest = {
      search: searchText,
      dateTo: endDate,
      dateFrom: startDate,
      eventType: filterBy as EventType
    };

    if (!searchText) {
      exportLogsRequest = { ...exportLogsRequest, search: '' };
    }
    if (!filterBy) {
      exportLogsRequest = { ...exportLogsRequest, eventType: '' as EventType };
    }

    try {
      await exportLogs(exportLogsRequest);
      setSnack({
        isError: false,
        open: true,
        message: t('export.success')
      });
    } catch (error) {
      setSnack({
        isError: true,
        open: true,
        message: t('export.error')
      });
    }
  };

  return (
    <>
      <h2 className="font-audi-extended-bold">{t('home.pageText')}</h2>
      <SearchWithDatePicker
        searchText={searchText}
        setSearchText={setSearchText}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      {meta && (
        <ResultsPanel
          resultsNumber={meta.totalItems}
          currentPage={currentPage}
          setResultsPerPage={setResultsPerPage}
          resultsPerPageOptions={resultsPerPageOtions}
        />
      )}
      {items && <HomeTable items={items} setFilterBy={setFilterBy} />}
      {meta && (
        <TableFooter
          resultsNumber={meta.totalItems}
          currentPage={currentPage}
          pageCount={meta.totalPages}
          setCurrentPage={setCurrentPage}
          exportData={exportData}
        />
      )}
    </>
  );
};

export default HomePage;
