import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './ResultsInfo.scss';

interface ResultsInfoProps {
  resultsNumber: number;
  currentPage: number;
}

const ResultsInfo = ({ resultsNumber, currentPage }: ResultsInfoProps) => {
  const { t } = useTranslation();

  const numberWithDots = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <>
      <Grid item className="results-info-container">
        <Grid container spacing={2}>
          <Grid item>
            {numberWithDots(resultsNumber)} {t('home.results.resultCount')}
          </Grid>
          <Grid item>
            {currentPage}
            {t('home.results.thPage')}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ResultsInfo;
