export enum EventType {
  ADMIN_LOGIN_FAILED = 'AdminLoginFailed',
  LOGIN_FAILED = 'LoginFailed',
  DATA_POLICY_ACCEPTED = 'DataPolicyAccepted',
  ACCOUNT_LOCK = 'AccountLock',
  ACCOUNT_UNLOCK = 'AccountUnlock',
  PAYMENT_SUCCESSFUL = 'PaymentSuccessful',
  PAYMENT_FAILED = 'PaymentFailed',
  UNIPFR_PROCESS_SUCCESSFUL = 'UnipfrProcessSuccessful',
  UNIPFR_PROCESS_FAILED = 'UnipfrProcessFailed',
  USER_DELETED = 'UserDeleted'
}

export default EventType;
