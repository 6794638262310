import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { ApiClient } from '../apiClient/apiClient';
import { IAdminLoginRequest } from '../interfaces/requests/auth/adminLogin.request';
import { IJWTPayload } from './../interfaces/models/jwt.payload.model';

const api = ApiClient.getInstance();

const adminLogin = async (loginRequest: IAdminLoginRequest) => {
  const response = await api
    .post('auth/adminLogin', loginRequest)
    .then((res) => {
      localStorage.setItem('accessToken', res.data.accessToken);

      let expiresAt = moment().add(15, 'm').toISOString();
      localStorage.setItem('expiresAt', expiresAt);

      localStorage.setItem('termsAcceptedAt', moment().toISOString());
    })
    .catch((error) => {
      return error.response.data.message;
    });

  return response;
};

const refreshToken = async () => {
  const token = localStorage.getItem('accessToken');

  let payload: IJWTPayload = jwt_decode(token!);
  const params = { userId: payload.userId, tokenUUID: payload.tokenUUID, platform: 'Admin' };

  const response = await api.post('auth/refreshToken', params);
  return response;
};

const revokeToken = async () => {
  const token = localStorage.getItem('accessToken');

  let payload: IJWTPayload = jwt_decode(token!);

  const params = { tokenUUID: payload.tokenUUID, platform: 'Admin' };
  const response = await api.post('auth/revokeToken', params);
  return response;
};

const isUserLoggedIn = async (): Promise<boolean> => {
  const token = localStorage.getItem('accessToken');

  if (token) {
    const payload: IJWTPayload = jwt_decode(token);
    const isAdmin = payload.isAdmin;

    if (!isAdmin) {
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  return Promise.resolve(false);
}

export { adminLogin, revokeToken, isUserLoggedIn, refreshToken };
