import { Autocomplete, Grid, TextField } from '@mui/material';
import { FunctionComponent, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IGetUserByPersonCodeRequest,
  IUpdateAdminRequest
} from '../../../interfaces/requests/users/users.request';
import { IUsersResponse } from '../../../interfaces/responses/users/users.response';
import { getUserByPersonCode, getUsers, updateAdmin } from '../../../services/users.service';
import { SnackbarContext } from '../../SnackbarAlert/SnackbarAlert';
import CustomButton from '../../UI/CustomButton/CustomButton';
import Modal from '../../UI/Modal/Modal';
import './AddAdministratorModal.scss';

interface CustomModalProps {
  isVisible: boolean;
  closeModalHandler: () => void;
  getAdminData: () => void;
}

type AdministratorItemType = {
  id: string;
  personCode: string;
};

const AddAdministratorModal: FunctionComponent<CustomModalProps> = memo(
  ({ isVisible, closeModalHandler, getAdminData }) => {
    const { t } = useTranslation();
    const [personCode, setPersonCode] = useState('');
    const [modalClosing, setModalClosing] = useState(false);
    const [items, setItems] = useState<IUsersResponse[]>([]);
    const { setSnack } = useContext(SnackbarContext);

    const getUserData = async () => {
      let data = await getUsers();
      data = data.filter((item) => !item.isAdmin);
      setItems(data);
    };

    useEffect(() => {
      getUserData();
    }, []);

    const autoCompleteProps = {
      options: items,
      getOptionLabel: (option: AdministratorItemType) => option.personCode
    };

    const modalClose = () => {
      setModalClosing(true);
      setTimeout(() => {
        setModalClosing(false);
      }, 500);
    };

    const handleAddAdministrator = async () => {
      const getUserByPersonCodeRequest: IGetUserByPersonCodeRequest = {
        personCode
      };

      const user = await getUserByPersonCode(getUserByPersonCodeRequest);

      const updateAdminRequest: IUpdateAdminRequest = {
        id: user.id,
        personCode: user.personCode,
        cardCode: user.cardCode,
        termsAcceptedAt: user.termsAcceptedAt,
        blockedUntil: user.blockedUntil,
        loginAttempts: user.loginAttempts,
        isAdmin: true,
        createdAt: user.createdAt,
        updatedAt: user.updatedAt
      };

      if (await updateAdmin(updateAdminRequest)) {
        setSnack({
          isError: false,
          open: true,
          message: t('administratorsPage.modal.successfulAddition')
        });
        getAdminData();
      } else {
        setSnack({
          isError: false,
          open: true,
          message: t('general.error')
        });
      }

      modalClose();
    };

    const inputOnlyNumbers = (val: string) => {
      setPersonCode(val.replace(/\D/g, ''));
    };

    return (
      <Modal
        isVisible={isVisible}
        closeModalHandler={closeModalHandler}
        modalClosing={modalClosing}
        customClass="add-admin-modal">
        <Grid container direction="column" spacing={2} className="add-admin-modal-content">
          <Grid item>
            <div className="align-middle font-audi-extended-bold">
              <h2>{t('administratorsPage.modal.addNewAdministrator')}</h2>
            </div>
          </Grid>
          <Grid item className="person-number-input-container">
            <div className="align-middle">
              <Autocomplete
                {...autoCompleteProps}
                disableClearable
                disablePortal
                autoHighlight
                noOptionsText={'Your Customized No Options Text'}
                onChange={(event, value) => {
                  inputOnlyNumbers(value.personCode);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="custom-input"
                    value={personCode}
                    onChange={(e) => inputOnlyNumbers(e.target.value)}
                    label={t('administratorsPage.modal.personCode')}
                    variant="standard"
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item>
            <div className="align-middle">
              <CustomButton
                text={t('administratorsPage.modal.add')}
                customFunction={handleAddAdministrator}
              />
            </div>
          </Grid>
        </Grid>
      </Modal>
    );
  }
);

export default memo(AddAdministratorModal);
