import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-small.svg';
import CustomDatePicker from '../UI/CustomDatePicker/CustomDatePicker';
import CustomSearch from '../UI/CustomSearch/CustomSearch';
import moment from 'moment';

interface SearchWithDatePickerProps {
  searchText: string;
  setSearchText: (searchText: string) => void;
  startDate: null | string;
  setStartDate: (startDate: string) => void;
  endDate: null | string;
  setEndDate: (endDate: string) => void;
}

const SearchWithDatePicker = ({
  searchText,
  setSearchText,
  startDate,
  setStartDate,
  endDate,
  setEndDate
}: SearchWithDatePickerProps) => {
  const { t } = useTranslation();

  const setAndConvertStartDate = (date: string) => {
    setStartDate(moment(date).format('YYYY-MM-DD'));
  };

  const setAndConvertEndDate = (date: string) => {
    setEndDate(moment(date).format('YYYY-MM-DD'));
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ mb: 5 }}>
        <Grid item>
          <CustomSearch
            value={searchText}
            setValue={setSearchText}
            debounceTime={500}
            placeholderText={t('common.searchPlaceholder')}
            icon={<SearchIcon />}
          />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            align-items="flex-end"
            spacing={2}>
            <Grid item>
              <CustomDatePicker
                label={t('datePicker.startDate')}
                datePickerValueChange={setAndConvertStartDate}
                value={startDate}
              />
            </Grid>
            <Grid item>
              <CustomDatePicker
                label={t('datePicker.endDate')}
                datePickerValueChange={setAndConvertEndDate}
                value={endDate}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchWithDatePicker;
